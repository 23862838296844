<template>
  <div>
    <div v-if="!clubConfig.name">
      <a
        class="btn btn-primary font-weight-bold d-none d-sm-inline"
        :class="this.klass"
        v-on:click="reserveOnDesktopGeneric"
        style="
          color: black !important;
          background-color: #30abc6 !important;
          border: 1px solid silver;
        "
      >
        {{ this.text }}
      </a>

      <a
        class="btn btn-primary font-weight-bold d-inline-xs d-sm-none card-animation-on-hover"
        :class="this.klass"
        style="
          color: black !important;
          background-color: #30abc6 !important;
          border: 1px solid silver;
        "
        v-on:click="reserveOnMobileGeneric"
      >
        {{ this.text }}
      </a>
    </div>
    <div v-if="clubConfig.name">
      <a
        href="#"
        :class="this.klass"
        :style="{ backgroundColor: clubConfig.color }"
        class="btn btn-primary font-weight-bold d-none d-sm-inline card-animation-on-hover"
        v-on:click="reserveOnDesktopClub(clubConfig)"
      >
        {{ this.text }}
      </a>

      <a
        href="#"
        :class="this.klass"
        :style="{ backgroundColor: clubConfig.color }"
        class="btn btn-primary font-weight-bold d-inline-xs d-sm-none card-animation-on-hover"
        v-on:click="reserveOnMobileClub(clubConfig)"
      >
        {{ this.text }}
      </a>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';

export default {
  name: 'RsvpViaDialog',
  props: {
    klass: {
      type: String,
      default: 'btn-sm',
    },
    clubConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    text: {
      type: String,
      default: ' 📲 INFO & RESERVATIONS',
    },
    legend: {
      type: String,
      default: null,
    },
  },
  methods: {
    reserveOnDesktopGeneric() {
      this.rsvpDesktopDialogGeneric();
      // this.onFreeConciergeClick('desktop');
    },
    reserveOnMobileGeneric() {
      this.rsvpMobileDialogGeneric();
    },

    reserveOnDesktopClub(club) {
      this.rsvpDesktopDialogClub(club);
    },
    reserveOnMobileClub(club) {
      this.rsvpMobileDialogForClub(club);
    },
    onFreeConciergeClick(club) {
      let value = 30;
      this.$gtag.event('reservations', {
        value: value,
        event_category: 'clubs',
        event_label: club.name
      });
      this.$rollbar.info('Clubs: Info & Reservations:' + club.name, {club: club.name});
    },

    replaceTag(string, club) {
      return string.replace('{{club}}', club);
    },
    rsvpMobileDialogForClub(clubConfig) {
      let message = this.replaceTag(
        this.SMS_MESSAGE_CLUB_SPECIFIC,
        clubConfig.name
      );
      let options = Object.assign({}, this.BASE_MODAL, {
        title: '',
        html:
          `<img src="${clubConfig.logo}" height="60px" style="border: 1px solid black;border-radius: 360px"><br>` +
          `<br><small>${this.PERSUASIVE_TEXT_FOR_CLUB()}</small>`,

        footer: this.mailFooter(clubConfig),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          open(`https://wa.me/${this.PHONE_NUMBER}?text=${message}`);
          this.onFreeConciergeClick({name: "wa clubs info"});
        } else if (result.isDenied) {
          open(`sms:+${this.PHONE_NUMBER}&body=${message}`);
          this.onFreeConciergeClick({name: "sms clubs info"});
        }
      });
    },

    rsvpDesktopDialogClub(clubConfig) {
      let options = Object.assign({}, this.BASE_MODAL, {
        title: '',
        html:
          `<img src="${clubConfig.logo}" height="60px" style="border: 1px solid black;border-radius: 360px"><br>` +
          `<br><small>${this.PERSUASIVE_TEXT_FOR_CLUB()}</small>`,
        footer: this.mailFooter(clubConfig),
        background: clubConfig.color,
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          this.rsvpDesktopWhatsQR(clubConfig.name);
          this.onFreeConciergeClick(clubConfig);
        } else if (result.isDenied) {
          this.rsvpDesktopSmsQR(clubConfig.name);
          this.onFreeConciergeClick(clubConfig);
        }
      });
    },

    rsvpMobileDialogGeneric() {
      let options = Object.assign({}, this.BASE_MODAL, {
        html: '<img src="https://imgix.cosmicjs.com/ec030ce0-f3e4-11ee-b555-0d0678c27dd7-Orthodox-priest-with-white-beard-listening-to-musi.jpg?w=250&h=100&fit=clamp" height="100px" width="250px"><br>'
          + `<br><p>${this.PERSUASIVE_TEXT_GENERIC()}</p>`,
        footer: this.mailFooter({name: 'clubs'}),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          this.onFreeConciergeClick({name: "wa clubs info"});
          open(
            `https://wa.me/${this.PHONE_NUMBER}?text=${this.SMS_MESSAGE_GENERIC}`
          );
        } else if (result.isDenied) {
          this.onFreeConciergeClick({name: "sms clubs info"});
          open(`sms:+${this.PHONE_NUMBER}&body=${this.SMS_MESSAGE_GENERIC}`);
        }
      });
    },

    rsvpDesktopDialogGeneric() {
      let options = Object.assign({}, this.BASE_MODAL, {
        html: '<img src="https://imgix.cosmicjs.com/ec030ce0-f3e4-11ee-b555-0d0678c27dd7-Orthodox-priest-with-white-beard-listening-to-musi.jpg?w=250&h=100&fit=clamp" height="100px" width="250px"><br>'
          + `<br><p>${this.PERSUASIVE_TEXT_GENERIC()}</p>`,
        footer: this.mailFooter({name: 'clubs'}),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          this.rsvpDesktopWhatsQR();
          this.onFreeConciergeClick({name: "wa clubs info"});
        } else if (result.isDenied) {
          this.rsvpDesktopSmsQR();
          this.onFreeConciergeClick({name: "sms clubs info"});
        }
      });
    },
    rsvpDesktopShowQR(club, qrImageUrl) {
      let options = Object.assign({}, this.BASE_MODAL, {
        html: `<p>Scan the QR and send us a message with your inquiry. We'll be glad to help.</p> `,
        imageWidth: 270,
        imageHeight: 270,
        showDenyButton: false,
        showConfirmButton: false,
        footer:
          '<small > We operate from 9AM - 10PM / Answer within 15 mins</small>',
        imageUrl: qrImageUrl,
        imageAlt: 'QR for Contact',
      });
      Swal.fire(options).then((result) => {
      });
    },
    rsvpDesktopWhatsQR(club) {
      this.rsvpDesktopShowQR(
        club,
        'https://imgix.cosmicjs.com/c0eeaec0-5f68-11ef-b5ae-a594bb4a8e67-qr-code-whats.png'
      );
    },
    rsvpDesktopSmsQR(club) {
      this.rsvpDesktopShowQR(
        club,
        'https://imgix.cosmicjs.com/6f2d7e10-5f66-11ef-b5ae-a594bb4a8e67-qr-code-sms.png'
      );
    },
    PERSUASIVE_TEXT_FOR_CLUB() {
      Array.prototype.random = function () {
        return this[Math.floor(Math.random() * this.length)];
      };
      return this.legend || this.PERSUASIVE_TEXT.random();
    },
    PERSUASIVE_TEXT_GENERIC() {
      Array.prototype.random = function () {
        return this[Math.floor(Math.random() * this.length)];
      };
      return this.legend || this.PERSUASIVE_TEXT.random();
    },
    mailto: (club) =>
      `mailto:bookings+${
        club.name.toLowerCase().replaceAll("@", "").replaceAll("|", "").replaceAll(/\s/g, '-') || ''
      }@cabo.party`,
    mailFooter(club) {
      return `<div class="text-center">
          <br>
          <a href="${this.mailto(
        club
      )}" class="btn btn-sm btn-primary text-center" > via email</a>
          <br>
          <br>
          <small>We operate from 9AM - 10PM / Answer within 15 mins</small>
        </div>`;
    },
  },

  data: () => {
    const TITLE =
      '<span style="font-weight: bold;font-size: 16px;color: #efefef;letter-spacing: 5px;font-weight: bolder">CABO<span style="color:#66cdaa;font-size: 33px" >.</span>PARTY</span>\n';
    return {
      PHONE_NUMBER: 524421177251,
      BASE_MODAL: {
        backdrop: true,
        html: '',
        title: TITLE,
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'WhatsApp',
        denyButtonText: `SMS`,
        imageWidth: 250,
        imageHeight: 250,
        confirmButtonColor: 'black',
        denyButtonColor: 'black',
        imageAlt: 'Custom image',
        background: '#000000',
        onBeforeOpen: () => {
        },
      },
      PERSUASIVE_TEXT_CLUBS: [
        "Unlock Los Cabos's Magic - Book Now!",
        'Discover the best of Los Cabos-  Book Now!',
        'Any questions? Get recommendations and support from our Concierge team.',
        "Unlock Los Cabo's Magic - Secure your spot now",
      ],
      PERSUASIVE_TEXT: [
        'Data-driven choices, we know the scene inside out. Ask our special agents!',
        'No extra fees, no hidden costs to weigh you down. Book through us!',
        "In the world of your queries, we'll play our part. Just drop us a message",
        "Unlock Los Cabos Magic - Secure your spot with us now!",
        "We've got your back from start to finish.",
      ],
      SMS_MESSAGE_GENERIC: 'Hey there, how can we help you today?',
      SMS_MESSAGE_CLUB_SPECIFIC:
        'RSVP:%20{{club}}%0a--------%0aFULL_NAME:%20%0aDATE:%20%0aARRIVAL_TIME:%20%0aGROUP_SIZE:%20',
    };
  },
};
</script>
<style scoped>
p,
h1,
h2,
h3,
h4 {
  color: black !important;
}

button {
  color: black !important;
}
</style>
